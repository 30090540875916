<template>
  <div class="auth-wrapper auth-v1 px-2">
    <div class="auth-inner py-2">

      <!-- Register v1 -->
      <b-card class="mb-0">
        <b-link class="brand-logo">
          <!-- <vuexy-logo /> -->
          <img width="200" height="50" src="@/assets/images/logo.png" alt="">
          <!-- <h2 class="brand-text text-primary ml-1">
            Vuexy
          </h2> -->
        </b-link>

        <b-card-title class="mb-1">
          <!-- {{$t('Registeration')}} -->
        </b-card-title>
        <!-- <b-card-text class="mb-2">
          Make your app management easy and fun!
        </b-card-text> -->
        <!-- UserType -->
        <b-form-group :label="$t('registerasanew')">
          <b-form-radio-group id="UserType-radio-group" v-model="UserType" name="skin" :options="userOptions" />
        </b-form-group>
        <!-- form -->
        <validation-observer ref="registerForm">
          <b-form class="auth-register-form mt-2" @submit.prevent="registerUserJWt">

            <!-- UserName -->
            <b-form-group :label="$t('UserName')" label-for="UserName">
              <validation-provider #default="{ errors }" name="Username" rules="required|min:5">
                <b-form-input id="UserName" v-model="UserName" :state="errors.length > 0 ? false : null"
                  name="register-UserName" placeholder="johndoe" />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <!-- FullName -->
            <b-form-group :label="$t('FullName')" label-for="nameEN">
              <validation-provider #default="{ errors }" name="FullName" rules="required">
                <b-form-input id="FullName" v-model="nameEN" :state="errors.length > 0 ? false : null"
                  name="register-nameEN" placeholder="john" />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <!-- email -->
            <b-form-group :label="$t('Email')" label-for="email">
              <validation-provider #default="{ errors }" name="Email" rules="required|email">
                <b-form-input id="email" v-model="email" :state="errors.length > 0 ? false : null" name="register-email"
                  placeholder="john@example.com" />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            <!-- password -->
            <b-form-group :label="$t('Password')" label-for="password">
              <validation-provider #default="{ errors }" name="Password" rules="required">
                <b-input-group class="input-group-merge" :class="errors.length > 0 ? 'is-invalid' : null">
                  <b-form-input id="password" v-model="password" @input="checkPassword" :type="passwordFieldType"
                    :state="errors.length > 0 ? false : null" class="form-control-merge" name="register-password"
                    placeholder="············" />
                  <b-input-group-append is-text>
                    <feather-icon :icon="passwordToggleIcon" class="cursor-pointer" @click="togglePasswordVisibility" />
                  </b-input-group-append>
                </b-input-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            <ul class="ml-2 mt-3">
              <li v-bind:class="{ is_valid: contains_eight_characters }">
                8 {{ $t("Characters") }}
              </li>
              <li v-bind:class="{ is_valid: contains_number }">
                {{ $t("ContainsNumber") }}
              </li>
              <li v-bind:class="{ is_valid: contains_uppercase }">
                {{ $t("ContainsUppercase") }}
              </li>

              <li v-bind:class="{ is_valid: contains_lowercase }">
                {{ $t("ContainsLowercase") }}
              </li>
              <li v-bind:class="{ is_valid: contains_special_character }">
                {{ $t("ContainsSpecialCharacter") }}
              </li>
            </ul>

            <!--confirm password -->
            <b-form-group :label="$t('Confirmpassword')" label-for="password">
              <validation-provider #default="{ errors }" name="confirm_password" rules="required">
                <b-input-group class="input-group-merge" :class="errors.length > 0 ? 'is-invalid' : null">
                  <b-form-input id="password" v-model="confirm_password" :type="passwordFieldType"
                    :state="errors.length > 0 ? false : null" class="form-control-merge" name="register-confirm_password"
                    placeholder="············" />
                  <b-input-group-append is-text>
                    <feather-icon :icon="passwordToggleIcon" class="cursor-pointer" @click="togglePasswordVisibility" />
                  </b-input-group-append>
                </b-input-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            <!-- checkbox -->
            <b-form-group>
              <b-form-checkbox id="register-privacy-policy" v-model="isTermsConditionAccepted" name="checkbox-1">

                <b-link>{{ $t("AcceptTermsConditions") }}</b-link>
              </b-form-checkbox>
            </b-form-group>
            <b-row class="full-width">

              <a class="m-1" v-if="!$store.state.appConfig.isRTL" target="_blank"
                :href="baseURL + 'files/TermsAndConditionsRegistration.pdf'" color="success">
                <feather-icon icon="DownloadIcon" class="cursor-pointer "></feather-icon>
                <span style="font-size: 0.95rem">{{ $t("TermsAndConditions") }}</span>
              </a>
              <br />
              <a class="m-1" v-if="!$store.state.appConfig.isRTL" target="_blank"
                :href="baseURL + 'files/Patient_Waiver.pdf'" color="success">
                <feather-icon icon="DownloadIcon" class="cursor-pointer"></feather-icon>
                <span style="font-size: 0.95rem">{{ $t("PatientWaiver") }}</span>
              </a>
              <a class="m-1" v-if="$store.state.appConfig.isRTL" target="_blank"
                :href="baseURL + 'files/ArabDocliniaTerms480egp.pdf'" color="success">
                <feather-icon icon="DownloadIcon" class="cursor-pointer "></feather-icon>
                <span style="font-size: 0.95rem">{{ $t("TermsAndConditions") }}</span>
              </a>
              <br />
              <a class="m-1" v-if="$store.state.appConfig.isRTL" target="_blank"
                :href="baseURL + 'files/Patient_Waiver.pdf'" color="success">
                <feather-icon icon="DownloadIcon" class="cursor-pointer "></feather-icon>
                <span style="font-size: 0.95rem">{{ $t("PatientWaiver") }}</span>
              </a>
            </b-row>
            <b-form-group>
              <b-form-checkbox id="register-privacy-policy2" v-model="isAcceptPatientAbove18Years" name="checkbox-1">
                <b-link>{{ $t("AcceptPatientAbove18Years") }}</b-link>
              </b-form-checkbox>
            </b-form-group>
            <!-- submit button -->
            <b-button variant="primary" block type="submit" :disabled="!validateForm || !valid_password">
              {{ $t('Signup') }}
            </b-button>
          </b-form>
        </validation-observer>

        <b-card-text class="text-center mt-2">
          <span>{{ $t('Alreadyhaveanaccount') }}</span>
          <b-link :to="{ name: 'auth-login-v1' }">
            <span>{{ $t('Signininstead') }}</span>
          </b-link>
        </b-card-text>

        <div class="divider my-2">
          <div class="divider-text">
            {{ $t('or') }}
          </div>
        </div>

        <!-- social buttons -->
        <div class="auth-footer-btn d-flex justify-content-center">
          <b-button variant="facebook" href="javascript:void(0)">
            <feather-icon icon="FacebookIcon" />
          </b-button>
          <b-button variant="twitter" href="javascript:void(0)">
            <feather-icon icon="TwitterIcon" />
          </b-button>
          <b-button variant="google" href="javascript:void(0)">
            <feather-icon icon="MailIcon" />
          </b-button>
          <b-button variant="github" href="javascript:void(0)">
            <feather-icon icon="GithubIcon" />
          </b-button>
        </div>
      </b-card>
      <!-- /Register v1 -->
    </div>
    <vs-popup title="" :active.sync="ShowSuccessPopup">
      <SuccessPopup :Message="$t('CompletedRegisterationData')" v-if="ShowSuccessPopup"
        @CloseSeccussPopup="CloseSeccussPopup" @closePop="this.$router.push('/pages/login')"></SuccessPopup>
    </vs-popup>
  </div>
</template>

<script>
import { domain } from "@/gloabelConstant.js"
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import VuexyLogo from '@core/layouts/components/Logo.vue'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import { email, required } from '@validations'
import {
  BButton, BCard, BCardText, BCardTitle, BCol, BForm, BFormCheckbox, BFormGroup, BFormInput, BFormRadioGroup, BInputGroup, BInputGroupAppend, BLink, BRow
} from 'bootstrap-vue'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import SuccessPopup from "./SuccessPopup.vue"
export default {
  components: {
    VuexyLogo,
    SuccessPopup,
    // BSV
    BRow,
    BCol,
    BCard,
    BLink,
    BCardTitle,
    BCardText,
    BForm,
    BButton,
    BFormInput,
    BFormGroup,
    BInputGroup,
    BInputGroupAppend,
    BFormCheckbox,
    // validations
    ValidationProvider,
    ValidationObserver,
    BFormRadioGroup
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      baseURL: domain,
      userOptions: [
        { text: this.$t('Patient'), value: 'Patient' },
        { text: this.$t('Doctor'), value: 'Doctor' },
        // { text: this.$t('Hospital'), value: 'Hospital' },
      ],
      email: '',
      UserName: '',
      password: '',
      nameEN: '',
      status: '',
      confirm_password: '',
      contains_eight_characters: false,
      contains_number: false,
      contains_uppercase: false,
      contains_lowercase: false,
      contains_special_character: false,
      UserType: 'Patient',
      isTermsConditionAccepted: false,
      isAcceptPatientAbove18Years: false,
      IsActive: false,
      ShowSuccessPopup: false,
      // validation rules
      required,
      email,
      UserProfileID: null,

    }
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    validateForm() {

      return (
        this.nameEN != "" &&
        this.email != "" &&
        this.password != "" &&
        this.UserName != "" &&
        this.confirm_password != "" &&
        this.isTermsConditionAccepted === true &&
        this.isAcceptPatientAbove18Years === true &&
        this.contains_eight_characters &&
        this.contains_number &&
        this.contains_uppercase &&
        this.contains_lowercase &&
        this.contains_special_character &&
        this.password == this.confirm_password

      );
    },
  },
  methods: {
    CloseSeccussPopup() {
      this.ShowSuccessPopup = false;
      //this.$router.push("/login");
      debugger
      //mkl
      if (this.UserType == "Doctor") {
        this.$router.push("/CompletedRegisterationDoctorData/" + this.UserProfileID);
      } else if (this.UserType == "Patient") {
        this.$router.push("/CompletedRegisterationData/" + this.UserProfileID);
      }
    },
    checkPassword() {
      this.password_length = this.password.length;
      const format = /[ !@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]/;

      if (this.password_length > 8) {
        this.contains_eight_characters = true;
      } else {
        this.contains_eight_characters = false;
      }

      this.contains_number = /\d/.test(this.password);
      this.contains_uppercase = /[A-Z]/.test(this.password);
      this.contains_lowercase = /[a-z]/.test(this.password);
      this.contains_special_character = format.test(this.password);

      if (
        this.contains_eight_characters === true &&
        this.contains_special_character === true &&
        this.contains_uppercase === true &&
        this.contains_lowercase === true &&
        this.contains_number === true
      ) {
        this.valid_password = true;
      } else {
        this.valid_password = false;
      }
    },
    checkLogin() {
      // If user is already logged in notify
      if (this.$store.state.auth.isUserLoggedIn()) {
        this.$vs.notify({
          title: "Login Attempt",
          text: "You are already logged in!",
          iconPack: "feather",
          icon: "icon-alert-circle",
          color: "warning",
        });

        return false;
      }
      return true;
    },
    registerUserJWt() {
      var PhoneNumber = this.$route.params.Id;
      debugger
      // If form is not validated or user is already login return
      if (!this.validateForm || !this.checkLogin()) return;
      // if (this.UserType != "Doctor" && this.UserType != "Hospital") {
      //   this.IsConfirmed = true;
      //   this.IsActive = true;
      // }
      //|| this.UserType == "Hospital"
      if (this.UserType == "Doctor") {
        this.IsActive = true;
      }
      debugger
      const payload = {
        userDetails: {
          nameEN: this.nameEN,
          email: this.email,
          password: this.password,
          confirmPassword: this.confirm_password,
          userType: this.UserType,
          IsConfirmed: this.IsConfirmed,
          UserName: this.UserName,
          IsActive: this.IsActive,
          Phone: PhoneNumber,
        },
      };
      debugger;
      this.$vs.loading();
      this.$store
        .dispatch("auth/registerUserJWT", payload)
        .then((res) => {
          debugger;
          this.$vs.loading.close();
          this.ShowSuccessPopup = true;
          //var messge = res.data.Message;
          this.UserProfileID = res.data.Data;
          debugger
          //console.log(messge);
        })
        .catch((error) => {
          debugger;
          this.$vs.loading.close();
          this.$vs.notify({
            title: "regstration Attempt",
            text: error.response.data.Message,
            iconPack: "feather",
            icon: "icon-alert-circle",
            color: "danger",
          });
          debugger;
          console.log(error);
        });
    },
    validationForm() {
      this.$refs.registerForm.validate().then(success => {
        if (success) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Form Submitted',
              icon: 'EditIcon',
              variant: 'success',
            },
          })
        }
      })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
</style>
<style>
.material-icons {
  font-family: "Material Icons" !important;
}

.material-icons :lang(en) {
  font-family: unset;
}

.is_valid {
  color: rgba(136, 152, 170, 0.8);
}

.is_valid:before {
  width: 50%;
}

li {
  /* margin-bottom: 8px; */
  color: #525f7f;
  position: relative;
}

li:before {
  content: "";
  width: 0%;
  height: 2px;
  background: #2ecc71;
  position: absolute;
  left: 0;
  top: 50%;
  display: block;
  transition: all 0.6s cubic-bezier(0.175, 0.885, 0.32, 1.275);
}
</style>